import React from 'react';
import withLayout from '../layout';

import {StaticQuery, graphql} from "gatsby"
import ContactSlide from "../components/Contact/ContactSlide";
import {injectIntl} from "react-intl";
import Section from "../components/layout/Sections/Section";
import Img from "gatsby-image";

import './unia-europejska.scss';
import StandardPageComponent from "../components/Base/StandardPageComponent";

class UniaEuropejska extends StandardPageComponent {

	render() {
		return (
			<>
				<StaticQuery
					query={graphql`
            query {
               eu1: file(relativePath: { eq: "eu/1.png" }) {
	            childImageSharp {
	              fixed(width: 220, quality: 100) {
	                ...GatsbyImageSharpFixed
	              }
	            }
	           },
	           eu2: file(relativePath: { eq: "eu/2.png" }) {
	            childImageSharp {
	              fixed(width: 220, quality: 100) {
	                ...GatsbyImageSharpFixed
	              }
	            }
	           },
	           eu3: file(relativePath: { eq: "eu/3.png" }) {
	            childImageSharp {
	              fixed(width: 220, quality: 100) {
	                ...GatsbyImageSharpFixed
	              }
	            }
	          },
            }
          `}
					render={data => (
						<div className="page page--small-space page--static page--www page--padding-bottom-mobile">
							<Section modifiers={['text', 'padding-x']} modifiersLayout={['padding']}>
								<div className="ue-logos">
									<div className="ue-logos--item">
										<Img className='image' fixed={data.eu1.childImageSharp.fixed} />
									</div>
									<div className="ue-logos--item">
										<Img className='image' fixed={data.eu2.childImageSharp.fixed} />
									</div>
									<div className="ue-logos--item">
										<Img className='image' fixed={data.eu3.childImageSharp.fixed} />
									</div>
								</div>
								<>
									<strong>INTELLECT S.C.</strong> realizuje projekt dofinansowany z Funduszy
									Europejskich pn. <strong>„Opracowanie hybrydowej technologii analizy obrazu z
									przeznaczeniem do monitorowania dostępności miejsc parkingowych” </strong>
									<br/><br/>
									<br/>
									<strong>Cel projektu:</strong>
									<br/>
									Celem projektu jest opracowanie nowego systemu wizyjnej identyfikacji wolnych miejsc
									parkingowych.
									<br/>
									<br/><strong> Dofinansowanie projektu z UE: </strong>
									<br/>
									3&nbsp;706&nbsp;509,00 zł
								</>
							</Section>
						</div>
					)}
				/>
				<ContactSlide standalone={true} titleTag="h2"/>
			</>
		)
	}
};

const customProps = {
	localeKey: 'eu',
};

export default withLayout(customProps)(injectIntl(UniaEuropejska));
